<template>
    <div id="smartro" class="smartro">
        <div v-show="show" ref="screen" class="screen" style="display: none;">
            <iframe ref="iframe" width="100%" height="700" frameborder="0" scrolling="true"></iframe>
        </div>
    
        <div v-show="show" ref="progress" class="progress" style="display: none;">
            <div class="spinner"></div>
        </div>
        <slot name="activator" :on="{ click: open }" />
    </div>
</template>

<script>
export default {
    props: {
        order: Object
    },
    data() {
        let {
            pickups,

            orderNo,
            orderName,
            paymentAmount,
            paymentMethod,
            
            buyer,
            receiver,

            telecom,
            authNumber,
        } = this.$props.order;
        
        let orderDate = this.$dayjs().format("YYYYMMDDHHmmss");

        switch(this.$props.order.paymentMethod){
            case "creditcard": { paymentMethod = "CARD"; break; }
            case "mobile": { paymentMethod = "CELLPHONE"; break; }
        }

        return {
            show: false,
            
            // 결제정보
            serviceId: "",
            orderNo,
            orderName,
            orderCode: "G00000",
            orderDate,
            paymentAmount,
            paymentMethod,
            buyerUsername: "",
            buyerName: receiver.name || '',
            buyerPhone: receiver.phone || '',
            buyerEmail: receiver.email || '',
            MID: "",
            encryptData: "",
            ediDate: "",

            telecom,
            authNumber,

            checkSum: null,

        };
    },
    mounted() {
        window.smartroComplete = (payload) => {
            this.$emit("complete", payload);
            this.$emit("change", payload);
            this.$emit("input", payload);
            this.show = false;
        };
    },
    beforeDestroy() {
        window.smartroComplete = undefined;
    },
    methods: {
        async pay() {
            this.show = true;
            this.$nextTick(() => {
                // 모바일일때 iframe 화면 보이기
                this.$refs.screen.style.display = "";
                this.$refs.progress.style.display = "";
                this.ui = "self";
                // if (navigator.userAgent.indexOf("Android") > -1 || navigator.userAgent.indexOf("iPhone") > -1) {
                // } else {
                //     // PC일때 iframe 가리고, 팝업
                //     this.$refs.screen.style.display = "none";
                //     this.$refs.progress.style.display = "none";
                //     this.ui = "popup";
                // }
                this.$refs.iframe.src = `/plugins/smartro/request?` + Object.keys(this.$data).reduce((query, key) => query + `${key}=${encodeURIComponent(this.$data[key])}&`, ``);
            });
        },
    },
};
</script>

<style scoped>
#smartro .screen {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 99999;
    overflow-y: scroll;
    background: rgba(0,0,0,0.5);
}
#smartro iframe {
    position: fixed;
    width: 100%;
    height: 100%;
}
.smartro .progress{
    position: fixed;
    width: 100%;
    height: 100%;
    left:0;
    top: 0;
    background: rgba(0,0,0,0.54);
}
.smartro .spinner {
    position: fixed;
    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 28px;
    width: 28px;
    animation: rotate 0.8s infinite linear;
    border: 8px solid #fff;
    border-right-color: transparent;
    border-radius: 50%;
}
@keyframes rotate {
    0%    { transform: rotate(0deg); }
    100%  { transform: rotate(360deg); }
}
</style>
