<template>
    <div v-show="show" class="popup-background" @click="close()">
        <div class="wayup popup" @click.stop>
            <div class="popup__head">
                <h2>카드 수기 결제 요청</h2>
            </div>
            <div class="popup__body">
                <table id="payTable" class="table-default">
                    <tr>
                        <th>주문명</th>
                        <td>
                            <input name="goodsName" :value="orderName" disabled> <!-- 필수: 상품명 (한글 기준 20자 이하) -->
                        </td>
                    </tr>
                    <tr>
                        <th>주문번호</th>
                        <td>
                            <input v-model="orderNo" name="moid" value="" disabled> <!-- 필수: 가맹점 주문번호 (중복방지, 영문 or 숫자) -->
                        </td>
                    </tr>
                    <tr>
                        <th>결제금액</th>
                        <td>
                            <input v-model="paymentAmount" name="amt" disabled> <!-- 필수: 결제요청금액 (숫자만 가능) -->
                        </td>
                    </tr>
                    <tr>
                        <th>구매자이름</th>
                        <td>
                            <input v-model="buyerName" name="buyerName" value="테스트" disabled> <!-- 필수: 결제자 이름 -->
                        </td>
                    </tr>
                    <tr>
                        <th>카드사</th>
                        <td>
                            <input v-model="cardBank" type="hidden" name="cardQuota" value="00"> <!-- 필수: 할부개월 (ex. 일시불: 00, 2개월: 02, 3개월 03 ...) -->
                            <select v-model="cardBank" name="cardBank">
                                <option value="01">비씨</option>
                                <option value="02">국민</option>
                                <option value="03">하나</option>
                                <option value="06">신한</option>
                                <option value="07">현대</option>
                                <option value="08">롯데</option>
                                <option value="11">시티</option>
                                <option value="12">농협</option>
                                <option value="13">수협</option>
                                <option value="15">우리</option>
                                <option value="21">광주</option>
                                <option value="22">전북</option>
                                <option value="23">제주</option>
                                <option value="32">우체국</option>
                                <option value="33">새마을금고</option>
                                <option value="41">신협</option>
                                <option value="42">저축은행</option>
                                <option value="43">산업</option>
                                <option value="44">카카오뱅크</option>
                                <option value="45">케이뱅크</option>
                                <option value="46">카카오머니</option>
                                <option value="47">강원</option>
                            </select>
                        </td>
                    </tr>
                    <tr>
                        <th>카드구분</th>
                        <td>
                            <input v-model="cardType" type="hidden" name="cardQuota" value="00"> <!-- 필수: 할부개월 (ex. 일시불: 00, 2개월: 02, 3개월 03 ...) -->
                            <select v-model="cardType" name="cardType">
                                <option value="01">개인</option>
                                <option value="02">법인</option>
                            </select>
                        </td>
                    </tr>
                    <tr>
                        <th>카드번호</th>
                        <td>
                            <div class="input-group">
                                <input v-model="cardNo" name="cardNum" type="hidden" value="">
                                <input v-model="cardNumbers[0]" name="cardNum1" value="" maxlength="4" placeholder="1234"> -
                                <input v-model="cardNumbers[1]" name="cardNum2" value="" maxlength="4" placeholder="1234"> -
                                <input v-model="cardNumbers[2]" name="cardNum3" value="" maxlength="4" placeholder="1234"> -
                                <input v-model="cardNumbers[3]" name="cardNum4" value="" maxlength="4" placeholder="1234"> <!-- 필수: 카드번호(-없이 숫자만) -->
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <th>유효기간</th>
                        <td>
                            <div class="input-group">
                                <input v-model="cardExp" name="cardExpire" type="hidden" value="">
                                <input v-model="cardExps[0]" name="cardExpire1" placeholder="MM" value="">
                                <input v-model="cardExps[1]" name="cardExpire2" placeholder="YY" value=""> <!-- 필수: 카드유효기간(년월숫자4자리, ex. 2020년 01월: 2001) -->
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <th>할부개월</th>
                        <td>
                            <input v-model="cardQuota" type="hidden" name="cardQuota" value="00"> <!-- 필수: 할부개월 (ex. 일시불: 00, 2개월: 02, 3개월 03 ...) -->
                            <select v-model="cardQuota" name="cardQuota">
                                <option value="">선택</option>
                                <option value="00">일시불</option>
                                <option v-for="month in 12" :key="month" :value="String(month).padStart(2, 0)">{{ month }}개월</option>
                            </select>
                        </td>
                    </tr>
                    <tr>
                        <th>카드비밀번호</th>
                        <td>
                            <input v-model="cardPassword" name="cardPwd" maxlength="2" type="password" value="" placeholder="앞 2자리"> <!-- 옵션: 카드비밀번호 (앞 2자리, 가맹점 옵션에 따른 입력 사항) -->
                        </td>
                    </tr>
                    <tr>
                        <th>인증번호</th>
                        <td>
                            <input v-model="authNo" name="buyerAuthNum" value="" disabled placeholder="생년월일 6자리(YYMMDD)"> <!-- 옵션: 생년월일 6자리(개인카드) or 사업자번호 10자리(법인카드) (가맹점 옵션에 따른 입력 사항) -->
                        </td>
                    </tr>
                </table>
            </div>
            <div class="popup__foot">
                <button class="button-grey" @click="close()">취소</button>
                <button class="button-primary" @click="submit()">결제</button>
            </div>
            <div v-show="loading" ref="progress" class="progress">
                <div class="spinner"></div>
            </div>
        </div>
    </div>
</template>

<script>
import api from "@/api";
export default {
    props: {
        order: Object
    },
    data: function(){

        var { orderNo, orderName, buyer, receiver, paymentAmount, paymentMethod } = this.order;

        return {
            show: false,
            loading: false,

            orderNo,
            orderName,
            quantity: 1,
            paymentAmount,
            buyerName: receiver.name,
            buyerPhone: receiver.phone,
            authNo: receiver.birthday?.substr?.(2, 6) || '', // 구매자 생년월일, YYMMDD

            cardBank: "",
            cardType: "01",
            cardNo: null,
            cardExp: null, // 카드 유효기간, YYMM
            cardQuota: "", // 카드 할부개월
            cardPassword: null, // 카드 카드비밀번호

            cardNumbers: ["", "", "", ""],
            cardExps: ["",""]
        };
    },
    methods: {
        pay: function(){
            this.show = true;
        },
        close: function(){
            this.show = false;
        },
        validate: function(){
            try{
                if(!this.cardBank) throw new Error("카드사를 선택해주세요");
                if(!this.cardQuota) throw new Error("할부개월을 선택해주세요");

                return true;
            }
            catch(error){
                alert(error.message);
            }
            return false;
        },
        async submit(){
            if(this.validate()){
                this.loading = true;

                let response = await api.plugins.wayup.pay.post({
                    orderNo: this.orderNo,
                    orderName: this.orderName,
                    quantity: this.quantity,
                    paymentAmount: this.paymentAmount,
                    buyerName: this.buyerName,
                    buyerPhone: this.buyerPhone,
                    authNo: this.authNo,
                    cardBank: this.cardBank,
                    cardType: this.cardType,
                    cardNo: this.cardNo,
                    cardExp: this.cardExp,
                    cardQuota: this.cardQuota,
                    cardPassword: this.cardPassword
                });

                this.loading = false;
                this.$emit("complete", response);
            }
        },
    },
    watch: {
        cardNumbers: {
            deep: true,
            handler: function(){
                this.cardNo = this.cardNumbers.join('');
                // console.log(this.cardNo);
            }
        },
        cardExps: {
            deep: true,
            handler: function(){
                this.cardExp = this.cardExps[1] + this.cardExps[0];
                // console.log(this.cardExp);
            }
        }
    }
}
</script>


<style scoped>
/* Table */
.table-default{
    width: 100%;
}
.table-default tr th,
.table-default tr td{
    height: 65px;
    font-size: 18px;
    color: #252525;
    padding:10px 0;
}
.table-default tr th{
    width: 120px;
    font-weight: 500;
    text-align: left;
    color: #252525;
}
.table-default input,
.table-default select{
    width: 100%;
    max-width: 100%;
    height: 45px;
    font-size: 18px;
    background: #fff;
    border: 1px solid #dfdfdf;
    border-radius: 6px;
    padding:0 20px;
}
.table-default [disabled="disabled"]{
    color: #b3b3b3 !important;
    background-color: #f7f7f7 !important;
}
@media (max-width:1024px){
}
@media (max-width:992px){
}
@media (max-width:768px){
    .table-default tr th,
    .table-default tr td{
        height: 50px;
        font-size: 16px;
        padding:6px 0;
    }
    .table-default tr th{
        width: 90px;
    }
    .table-default input,
    .table-default select{
        height: 40px;
        font-size: 16px;
        padding:0 10px;
    }
}
@media (max-width:576px){
    .table-default tr th,
    .table-default tr td,
    .table-default input,
    .table-default select{
        font-size: 14px;
    }
}


/* 카드수기 결제요청 */
.popup-background{
    position: fixed;
    top:0;
    left:0;
    z-index: 10001;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    padding: 20px 0;
    background-color: rgba(0,0,0,.15);
}
.popup{
    width: 100%;
    max-width: 600px;
    max-height: 100%;
    background-color: #fff;
    border-radius: 12px;
    box-shadow: 0 0 10px rgba(58,58,58,.2);
    overflow: auto;
}
.popup__head{
    text-align: left;
    padding:20px 40px;
    border-bottom:1px solid #ccc;
}
.popup__head h2{
    font-size: 26px;
    font-weight: 500;
    color:#252525;
    margin-bottom: 0;
}
.popup__body{
    padding:36px 40px;
}
.popup__foot{
    padding: 40px;
}
.popup__body + .popup__foot{
    padding-top: 0;
}
.popup__foot a,
.popup__foot button{
    width: 120px;
    height: 45px;
    font-size: 18px;
    font-weight: 500;
    color:#fff;
    border-radius: 6px;
    background-color: #b0b0b0;
    margin:0 5px;
}
.button-grey{
    background-color: #b0b0b0 !important;
}
.button-primary{
    background-color: #e93563 !important;
}
@media (max-width:1024px){
    .popup-background{
        padding:0;
    }
    .popup{
        border-radius: 0;
    }
    .popup__head h2{
        font-size: 18px;
    }
}
@media (max-width:992px){
}
@media (max-width:768px){
    .popup{
        height: 100%;
    }
    .popup__head{
        padding: 10px 15px;
    }
    .popup__body, .popup__foot{
        padding: 20px 15px;
    }
    .popup__foot a,
    .popup__foot button{
        width: 100px;
        height: 40px;
        font-size: 16px;
    }
}
@media (max-width:576px){
}

.input-group{
    display: flex;
    align-items: center;
    width: 100%;
    margin: 0 -2px;
}

.input-group>input{
    margin: 0 2px;
}

.wayup .progress{
    position: fixed;
    width: 100%;
    height: 100%;
    left:0;
    top: 0;
    background: rgba(0,0,0,0.54);
}
.wayup .spinner {
    position: fixed;
    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 28px;
    width: 28px;
    animation: rotate 0.8s infinite linear;
    border: 8px solid #fff;
    border-right-color: transparent;
    border-radius: 50%;
}
@keyframes rotate {
    0%    { transform: rotate(0deg); }
    100%  { transform: rotate(360deg); }
}
</style>
